<template>
  <div class="user-list-page">
    <en-table-layout
      :tableData="tableData.data"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <div class="col toolbar-title">
          用户管理
        </div>
        <div class="col-auto">
          <div class="row no-gutters align-items-center pt-3" style="float:right;">
            <el-form-item label="手机号">
              <el-input
                size="medium"
                style="width: 180px"
                v-model="inform.mobile"
                placeholder="请输入手机号"
                clearable
              />
            </el-form-item>
            <el-button
              @click="seachMember"
              size="small"
              type="primary"
            >
              搜索
            </el-button>
          </div>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="ID编号" width="80">
          <template slot-scope="scope">
            {{ idRules(scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="注册时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号">
          <template slot-scope="scope">
            {{ scope.row.mobile }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="用户名称">
          <template slot-scope="scope">
            {{ scope.row.nickname }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="210px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="handleEdit(scope.$index, scope.row)"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="inform.page_no"
        :page-sizes="MixinPageSizes"
        background
        :layout="MixinTableLayout"
        :page-size="inform.page_size"
        :total="total"
      />
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Users from '@/api/users';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import ImportMember from '@/views/users/ImportMember';

export default {
  components: {
    ImportMember,
    EnTableLayout,
  },
  data() {
    return {
      time: '',
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入用户名称',
            trigger: 'blur'
          },
        ],
        member_id: [
          {
            required: true,
            message: '请输入用户ID',
            trigger: 'blur'
          },
        ],
        mobile: [
          {
            required: true,
            message: '请输入电话号码',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '长度在11个字符',
            trigger: 'blur'
          },
        ],
        disabled: [
          {
            required: true,
            message: '请选择区域',
            trigger: 'change'
          },
        ],
      },
      num: 20,
      total: 0,
      formInline: {
        user: '',
        region: '',
      },
      inform: {
        keyword: '',
        nickname: '',
        page_size: 20,
        page_no: 1,
        disabled: '',
        mobile: '',
        member_id: '',
      },
      params: [],
      page: {},
      tableData: {},
      multipleSelection: [],
      isError: false,
      importData: [],
      importNum: 0,
    };
  },
  created() {
    this.getList();
    console.log(this.$route)
  },
  methods: {
    idRules(index) {
      let no = (index + (this.page_no - 1) * this.page_size).toString()
      if (no.length <= 4) {
        switch (no.length) {
          case 1:
            no = '000' + no
            break
          case 2:
            no = '00' + no
            break
          case 3:
            no = '0' + no
            break
          case 4:
            break
        }
      }
      return no
    },
    batchOps(ops) {
      switch (ops) {
        case 'enabled':
          return this.reinstatement();
        case 'disabled':
          return this.onClose();
        case 'setGroup':
          return this.setMemberGroup();
        case 'import':
          return this.importMore();
      }
    },
    bsearch() {
      this.abcd = false;
    },
    daterangeDateChange(val) {
      this.inform.start_time = val ? parseInt(val[0] / 1000) : '';
      this.inform.end_time = val ? parseInt(val[1] / 1000) : '';
    },
    //提交
    submitForm() {
      this.getList();
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    importMore() {
      this.$refs.importMember.show();
    },
    clearRes() {
      this.inform.keyword = '';
      this.inform.member_id = '';
      this.inform.mobile = '';
      this.time = '';
      this.inform.disabled = ''; //
      this.inform.start_time = '';
      this.inform.end_time = '';
      this.getList();
    },
    seachMember() {
      this.getList(1);
    },
    getList(page_no) {
      let params = this.inform;
      page_no && (params.page_no = 1);
      params.is_salesman = 0;
      API_Users.getMemberList(params).then((res) => {
        this.total = res.data_total;
        this.page_size = res.page_size;
        this.page_no = res.page_no;
        this.tableData = res;
      });
    },
    setMemberGroup() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请先选择用户');
      } else {
        this.$refs.addMember.show({
          num: 4,
          ids: this.params
        });
      }
    },
    addMember(row) {
      this.$refs.addMember.show({num: 1, ...row});
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.params = val.map((item, index) => {
        return item.member_id;
      });
      this.multipleSelection = val;
    },
    onClose() {
      if (this.multipleSelection.length === 0) {
        this.$message.error('请先选择用户');
      } else {
        if (this.params) {
          console.log(this.params);
          API_Users.closeMember(this.params).then((res) => {
            this.getList();
          });
        }
      }
    },
    reinstatement(index, row) {
      if (row) {
        API_Users.reinstatement(row.member_id).then((res) => {
          this.getList();
        });
      } else {
        if (this.multipleSelection.length === 0) {
          this.$message.error('请先选择用户');
        } else {
          if (this.params) {
            API_Users.reinstatement(this.params).then((res) => {
              this.getList();
            });
          }
        }
      }
    },
    handleEdit(index, row) {
      this.$router.push({
        path: '/combo-card-shop/users/user-msg',
        query: {id: row.member_id},
      });
    },
    handleDelete(index, row) {
      this.$confirm('是否要禁用此用户?', '提示', {
        type: 'warning',
      }).then(() => {
        API_Users.deleteMember(row.member_id).then((res) => {
          this.getList();
          this.$message.success('禁用成功');
        });
      });
    },
    handleSizeChange(val) {
      this.inform.page_size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.inform.page_no = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.importok {
  h1 {
  }
}

/deep/ .has-gutter tr th {
  text-align: center;
}

/deep/ .el-table__row td{
  text-align: center;
}

.moreUser {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -300px;
  margin-left: -375px;
  background-color: #fff;
  width: 750px;
  height: 600px;
}

.domnload1 {
  cursor: pointer;
  color: #0079fe;
}

.domnload1:hover {
  color: red;
}

.shadow {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 1000px;
}

.xleft {
  cursor: pointer;
  float: right;
}

.first {
  font-size: 14px;
  padding: 0 20px 0 20px;
}

.secend h2 {
  text-align: center;
  float: left;
  width: 50%;
}

.threeone {
  margin: 20px 10px;
  border: 1px solid rgba(233, 233, 233, 1);
  padding: 18px 0 10px 120px;
}

.threetwo {
  margin: 20px 10px;
  background-color: rgb(255, 235, 204);
  padding: 15px 20px 15px 60px;
}

.btn2 {
  padding-right: 10px;
  text-align: end;
}

.userAdimin {
  position: relative;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px;
}

.demo-ruleForm1 {
  z-index: 2000;
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 0;
  width: 300px;
  padding: 20px;
}

/deep/ .el-form-item__label {
  font-size: 12px;
}

/deep/ .el-input__inner {
  font-size: 12px;
}

/deep/ .el-range-input {
  font-size: 12px;
}

/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 168px;
}

.btn33 /deep/ .el-form-item__content {
  margin-left: 0 !important;
}

.posi {
  z-index: 99;
  position: absolute;
  left: 85px;
  top: 100px;
}

.search {
  padding-top: 10px;
}
.search .el-form-item {
  margin-bottom: 0;
}
</style>
